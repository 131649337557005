import { template as template_92574ebba2d24e5ca6f1c1de9b05f6cf } from "@ember/template-compiler";
const FKControlMenuContainer = template_92574ebba2d24e5ca6f1c1de9b05f6cf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
