import { template as template_f28c73b3a705438ab0c5b911a904486f } from "@ember/template-compiler";
const FKLabel = template_f28c73b3a705438ab0c5b911a904486f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
