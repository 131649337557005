import { template as template_50b8ddd682ee4e9c8da224b8fe40ce51 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_50b8ddd682ee4e9c8da224b8fe40ce51(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
