import { template as template_061afbd163b14015b1d0cb25d9852860 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import { i18n } from "discourse-i18n";
const UserSummarySection = template_061afbd163b14015b1d0cb25d9852860(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
